.cardBook{
    width: 15rem;
    border-radius:15px;
    margin: 2rem;
    border-color: #FF7C05;
    box-shadow: 0px 5px 15px 0px rgb(227 68 2 / 35%);
}

.hr{
    color: #FF7C05;
}

.searchBtn{
    background-color: #FF7C05;
    color: #fff;
    border: 1px solid #FF7C05;
    padding: 8px 25px;
    border-radius: 7px;
}

.searchBtn:disabled{
    background-color: #a1a1a1 !important;
    border: #a1a1a1;
}

.searchBtn:hover{
    background-color: #fff;
    color:#FF7C05;
    border-color:#FF7C05;
    padding: 8px 25px;
    border-radius: 7px;
}


.firstBook{
    text-decoration: underline;
    color: #FF7C05;
    text-align: center;
}

.bg{
    background-image: url(../assets/imgs/sec2Background.png);
    padding: 12px 0;

}

.form-control{
    padding: 12px;
}


input , select{
    margin: 10px 0;
}







.mainNotbook{
    background-image: url(../assets/imgs/Notebook.png);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 1rem 2rem;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 272px;
    height: 415px;
    margin: 1rem;
transition: 0.5s;
text-align: center;
overflow: hidden;
}

.mainNotbook:hover{
    transform: scale(1.1);
}

.mainNotbook hr{
    color: #FF7C05;
    width: 100%;
    height: 2px;
}