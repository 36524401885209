.socialIcons svg{
    width: 31px;
    height: 29px;
    margin-right: 24px;
}

.navMain{
    box-shadow: 0px 9px 6px -6px rgba(0, 0, 0, 0.3);
    position: sticky;
    top: 0;
    z-index: 222;

}

.socialIcons{
    color: #FF7C05;
}


.navLink{
    font-size: 18px;
}
.navLink:hover{
    color: #FF7C05;
}


.homeP{
    font-size: 18px;
}

a{
    text-decoration: none;
}



@media only screen and (max-width: 1000px) {
    .socialIcons {
        margin: 1rem 0 ;
    }
}