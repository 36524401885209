
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}




.form-check-input{
  border:2px solid #FF7C05;
}

.form-control:focus , .form-select:focus {
  box-shadow: 0 0 0 0.25rem #ff7c055e;
    border-color: #ff7c05ba;
}


.modal-header .btn-close{
  margin: 0 !important;
}

@media only screen and (max-width: 1000px) {
.navCollaps > div{
  max-height: fit-content !important;
}

.Toastify__toast-theme--light{
width: 70% !important;
}
}


.react-tel-input .form-control{
  text-align: start !important;
}





* {
  scrollbar-width: none;
  scrollbar-color: #105598 #000000;
}

/* Chrome, Edge, and Safari */
*::-webkit-scrollbar {
  width: 11px;
}

*::-webkit-scrollbar-track {
  background: #bababa;
}

*::-webkit-scrollbar-thumb {
  background-color: #FF7C05;
  border-radius: 10px;
  border: 3px solid #bababa;
}


.homeVid{
  position: relative;
  z-index: 20;
}