.sec1{
    background-image: url(../assets/imgs/homeHead.jpg);
    height: 92vh;
    background-repeat: no-repeat;
    background-size: cover;
    width: auto;
    display: flex;
    flex-direction: column;

    padding: 10rem 0 ;
}
.imagePuzzel{
    width: 400px;
}


.sec1Text{
    color: #000;
    border-right: 7px solid #FF7C05;
    padding: 14px 9px;
    display: inline;
    font-family: secondFont;
    font-size: 3rem;
    line-height: 1.5;
}

.sec1Div{
    width: 100%;
    text-align: center;
}


.btnMore{
    background-color: #FF7C05;
    padding: 8px 40px;
    border: none;
    font-size: 20px;
}


.btnMore:hover{
    background-color: #fff;
    color: #FF7C05;
    border: 1px solid #FF7C05;

}


.sec2{
    margin-top:10rem;
    background-color: #E6E6E6;
}

.image2:first-child{
    width: 600px;
    margin-right: 5rem;

}


.textHead{
    color: #FF7C05;
}

.sec3border1{
    border-right: 2px solid #FF7C05;
}


.sec3border2{
    border-left: 2px solid #FF7C05;
}

.soonImages{
    width: 190px;
    transition: 0.5s;
    margin: 0 3rem;
}


.soonImages:hover{
    transform: scale(1.1);
}


.homeButtons {
    background-color: #FF7C05;
    width: 30%;
    border-color: #FF7C05;
}


.homeButtons a{
    color: #fff;
}

.homeButtons:hover{
    background-color: #fff;
    color: #FF7C05;
    border-color: #FF7C05;
}



.homeButtons:hover a{
    color: #FF7C05;
}

.homeP{
    font-size: 20px;
}

.authResShow{
    display: none !important;
}
@media only screen and (max-width: 1000px) {

    .TabletView {
        flex-direction: column !important;
    }

    .TabletView div {
        text-align: start;
        width: 100%;
    }
    
.textHead{
 margin-top: 1rem;
 text-align: center;
}
    .sec2{
        margin-top: 0;
    }
    .sec1{
        background-image: url(../assets/imgs/mobile/homePageHeadMob.jpg);
        padding: 0;
    }

    .sec1Text{
        font-size: 2rem;
    }
.authResHide{
    display:none !important;
    
}
.authResShow{
    display:block !important;
}
.image2{
    width: 100%;
}

.image2:first-child{
    width: 100%;
    margin: 0;
}

.animtaedText{
    margin-top: 1rem;
}

.homeButtons{
    width: 50%;
}

.soonImages{
    margin: 2rem 0;
}

}


/* img{
    width: 300px;
} */




.sec1{
    overflow: hidden;
}
.resSec2{
    overflow: hidden;
}




