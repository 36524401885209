.tab div svg{
    font-size: 22px;
}

.tabs div div div .Mui-selected {
    color: #FF7C05 !important;
}


.tabText {
    padding:  0 6px ;
    font-family: myFirstFont;

}


.css-1h9z7r5-MuiButtonBase-root-MuiTab-root .Mui-selected{
    color: #FF7C05 !important;
}

.authData{
    display: flex;
    justify-content: center;
    align-items: center;
}

.hrHalf{
    background-color: #FF7C05;
    height: 3px;
    width: 40%;
}

.hrtext{
    margin: 0 1rem;
    font-family: myFirstFont;
    font-size:20px ;


}

.datafilds{
    display: flex;
    justify-content: space-around;
}

.dataFild{
    width: 300px;
    margin-top: 1rem;
}

.dataFild h4 , .dataFildRow h4{
    font-weight: 800;
    font-family: myFirstFont;


}

.dataFild h5 , .dataFildRow h5{
    font-weight: 400;
    color: rgb(92, 92, 92);
    font-family: myFirstFont;
    
}

.datafildsRow {
    display: flex;
    justify-content: center;
    margin-top: 1rem;
}

.dataFildRow{
    width: 75%;
}

.oldBooksContainer{
    display: flex;
    align-items: center;
    justify-content: center;
}

.oldbooksUL li {
    width: 500px;
    height: 35px;
    display: flex;
    align-items: center;
    margin: 1.5em;
    cursor: pointer;
    padding: 1em;
    background: rgb(43, 43, 43);
    position: relative;
    color: white;
    border-radius: 5px;
}
.oldbooksUL li::before,
.oldbooksUL li::after {
    content: "";
    position: absolute;
    z-index: -1;
    border-radius: 5px;
    width: 105%;
    transition: all 0.4s;
}
.oldbooksUL li::before {
    left: 0%;
    height: 130%;
    background: linear-gradient(to right,#FF7C05, #FF7C05);
}
.oldbooksUL li::after {
    left: -10%;
    height: 120%;
    background: #ffffff56;
    backdrop-filter: blur(10px);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.164);
}
.oldbooksUL li:hover::before {
    transform: translateX(-2.5%);
}
.oldbooksUL li:hover::after {
    transform: translateX(15%);
}






@media only screen and (max-width: 1000px) {
.datafilds{
    display: flex;
    /* justify-content: start; */
    flex-direction: column;
}

.dataFild{
    width: 100%;
    margin-top: 1rem;
    padding: 0;
}

.hrtext{
    width: 100%;
    text-align: center;
}

.UL{
    padding: 0;
    margin: 0;
}

}