.headerText{
    color: #FF7C05;
}

.hr{
    color: #FF7C05;
    background-color: #FF7C05;
    height: 5px;
}

.formCheck{
    direction: ltr !important;
    width: 35px !important;
    margin-right:15px ;
}

.formCheck input {
    border-color: #FF7C05;
}


.btnPlus{
    background-color:#FF7C05;
    color:#fff
}

.submitBtn{
    background-color:#FF7C05;
    color:#fff;
    padding: 9px 35px;

    
}

.submitBtn:hover{
    background-color:#fff;
    color:#FF7C05;
    border:1px solid #FF7C05;

}