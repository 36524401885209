/* signup.module.css */
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5); /* Adjust the opacity as needed */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000; /* Ensure the overlay is on top of other elements */
  }
  
  .spinner {
    border: 4px solid #FF7C05; /* Red color with opacity */
    border-top: 4px solid #fff; /* White color for the spinner */
    border-radius: 50%;
    width: 50px;
    height: 50px;
    animation: spin 1s linear infinite;
    color: red; /* Set the spinner color to red */
    background-color: #fff; /* Set the background color */
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.5); /* Add a shadow effect */
  }