
.image{
    width: 100%;
    transition: 0.5s;

}



.imageGray{
    /* opacity: 0.6; */
    filter: brightness(25%);
    width: 300px;
}
/* .image:hover{
    transform: scale(1.1);
} */



.button {
    background-color: #FF7C05;
    border-color: #FF7C05;
    padding: 12px 60px;
    font-size: 25px;
}

.button:hover {
    background-color: #fff;
    border-color: #FF7C05;
    color: #FF7C05;
    padding: 12px 60px;
    font-size: 25px;
}

.CommingSoon{
    margin-top: 3rem;
}



.buttonGray , .buttonGray:hover{
    background-color: #7d7d7d;
    border-color: #fff;
    padding: 12px 60px;
    font-size: 25px;
}

.mobileDesign{
    display: none !important;
}


@media only screen and (max-width: 1220px) {
 

    .webDesign{
        display: none;
    }

    .mobileDesign{
        display: block !important;
    }
}