/* Footer.module.css */

.footer-16371 {
    margin-top: 5rem;
    padding: 3rem 0;
    font-family: "Poppins", sans-serif;
    background-color: #000;
    color: #fff;
  }
  
  .footer-16371 .footer-site-logo {
    text-align: center;
    font-size: 2rem;
    font-weight: 900;
  }
  
  .footer-16371 .footer-site-logo img {
width: 130px;  }
  
  .footer-16371 .nav-links {
    padding: 0;
    margin: 0;
  }
  
  .footer-16371 .nav-links li {
    display: inline-block;
  }
  
  .footer-16371 .nav-links li a {
    padding: 10px;
    color: #fff;
  }
  
  .footer-16371 .nav-links li a:hover {
    color: #FF7C05;
  }
  .social{
    margin: 3rem 0;
  }
  .footer-16371 .social h3 {
    font-weight: bold;
    font-size: 18px;
  }

  .footer-16371 .copyright {
    color: #999999;
  }



  .socialIcons svg{
    width: 31px;
    height: 29px;
    margin-right: 24px;
}

ul{
    padding-left: 4rem;
    margin: 0 5rem;

}

.socialIcons{
    color: #FF7C05;
}

