.header{
    background-image: url(../assets/imgs/signUpAs.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;


}


.headerText{
    text-align: center;
    font-weight: 900;
    font-size: 3rem;
}

.cardsHeader{
    padding: 6rem 0;
    align-items: center;
    justify-content: center;
}


.card{
    width: 100%;
}

.signUpCart{
    background-color: #fff;
    padding-bottom:12px;
    width: 246px;
    margin: 1rem auto;
}

.mainCardcol{
}

.cardContent{
    padding: 0 10px;
}

.button{
    background-color: #FF7C05;
    color: #fff;
    border: none;
    padding: 14px 30px;
    /* margin-top: -7rem; */
    transition: transform 0.3s ease-in-out;
    /* position: relative; */
    /* width: 21%; */
    
    
}


.button a{
    color: #fff;
    font-size: 20px;
}

.button a:hover{
    color: #FF7C05;
}
.button:hover{
    background-color: #fff;
    color: #FF7C05;
    border: 1px dashed #FF7C05;
    transform: translateY(-10px);
}

.button:hover a{
    color: #FF7C05;
}
.button:hover:active{
    background-color: #fff;
    color: #FF7C05;
    border: 1px dashed #FF7C05;

}

.linkerHr{
    color: #FF7C05;
}

.cardContentStart{
    text-align: start;
    padding: 1rem;
    height: 14rem;
}




@media only screen and (max-width: 1300px) {

    .cardContentStart{
        height: 16rem;
    }

    .cardsRow{
        flex-direction: column;
    }
    .cardsHeader > div {
        width: 100%;
    }
    .header{
        background-size: cover;
        background-size: cover;
        background-position: right;
        }


        .button{
            margin-top: 5px;
            /* margin-top:20rem; */
            /* margin-right: -11rem; */
            /* padding: 10px 20px; */
        }
     
}