/* LoginForm.module.css */







  


  





  .mainLoginPage{
  background-color: rgb(230, 230, 230);
  font-family: myFirstFont;
}
  .loginHeader{
    background-image: url(../assets/imgs/LoginHeader.jpg);
    width: 100%;
    padding-top: 42%; /* Set the padding to maintain a specific aspect ratio (e.g., 2:1) */
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position: center;
    
  }



.loginCard2{
  box-shadow: 6px 6px 10px 0px rgba(0, 0, 0, 0.4);
  width: 30%;
  padding: 6rem 4rem;
background-color: #fff;
margin-top: -21rem;
z-index: 2;
border-radius: 17px;

}

.cardContainer{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.loginBtn {
  padding: 13px 40px 12px;
  background-color: #FF7C05;
  border-radius: 4px;
  font-size: 17px;
  font-weight: bold;
  line-height: 20px;
  color: #fff;
  margin-bottom: 24px;
  
}

.loginBtn:hover {
  border: 1px solid #FF7C05;
  background-color: transparent;
  color: #FF7C05;
}

.loginCardFooterNav a {
  font-size: 14px;
  color: #919aa3;
}
.loginCardFooterNav a:hover{
  color: #FF7C05;
}

.textReset{
  color: #919aa3;
}
.textReset:hover{
  color:#FF7C05 ;
}


  








@media (max-width: 1200px) {
  .loginCard2 {
    width: 100%;
  }
  .loginCard2{
    margin-top: 0rem;
  }
}