.aboutMobile{
    display: none;
}

.mainIamge{
    background-image: url(../assets/imgs/aboutHeader.jpg);
    height: 90vh;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: auto; /* Set width to 100% to ensure it does not exceed the page width */
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: center; */
    background-position: center;
}


.aboutLogo{
    width: 500px;
    margin-top: 1rem;
}


.aboutSec2{
    margin-top: 4rem;
    background-image: url(../assets/imgs/sec2Background.png);
    background-repeat: no-repeat;
    background-size: cover;
    width: auto; /* Set width to 100% to ensure it does not exceed the page width */

}

.row1Sec{
    margin-top: 3rem;
    margin-bottom: 5rem;
}

.headingText{
    margin: 3rem 0;
    font-size: 70px;
    font-weight: bold;
}


.paragraph{
    font-size: 20px;
}

.markSvg{
    font-size: 54px;
    color: #FF7C05;
    padding: 8px;

}

.bulb{
    max-width: 401px;
    height: 519px;
}

.sec3 {
    background-image: url('../assets/imgs/aboutMissionback.png');
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 80%; 
    margin: auto;
    display: flex;
    justify-content: center;
    align-items: center;
}
.sec3 img{
    width: 100%;
}

.advantiage{
    font-size: 65px;
}

.advantiageImg{
    width: 200px;
}

.lastSecI img{
    width: 100%;
}

.lastSecP{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.lastSecP p {
    font-size: 20px;
}

.arrows{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 10% !important;
}

.arrows svg{
    vertical-align: middle;
    font-size: 57px;
    margin-top: 6rem;
    fill:#FF7C05
}


@media only screen and (max-width: 1200px) {
    .bulb{
        display: none;
    }
    .before p ,.after p  {
        padding: 1px 3px;
    }

    .aboutSec2{
        background-image: url(../assets/imgs/sec2BackgroundMobile.jpg);
    }
    .aboutMobile{
        display: block;
    }
    .aboutDesktop{
        display: none;
    }


    .before , .after{
        width: 40% !important;
        background-color: #E6E6E6;
        border-radius: 11px 11px  1px 1px ;

        padding: 0 0 60px 0;
        margin: 0 5px;
    }

    .before  div, .after div{
        height: 140px;
    }


    .before p , .after p{
        text-align: center;
        margin: auto;
    }

    .before h1 {
        background-color: #000;
        color: #fff;
        padding: 13px;
        border-radius: 11px 11px  1px 1px ;
    }

    .before hr{
        color: #000;
    }


    .after hr{
        color: #FF7C05;
    }
    
    .after h1 {
        background-color: #FF7C05;
        color: #000;
        padding: 13px;
        border-radius: 11px 11px  1px 1px ;
    }

    .advantiageImg{
        width: 140px;
    }
    .advantiage{
        font-size: 40px;
    }
    .row1Sec{
        margin-bottom: 1rem;
        margin-top: 1rem;

    }
    .aboutLogo{
        width: 300px;
    }
    p , h1 ,h2 , h3 , h4 , h5 , h6 {
        padding: 0 1rem;
    }
    .lastSecI img{
        transform: rotate(90deg);
    }
    .sec3{
        width: 100%;
        overflow: hidden;
    }
    .sec3 img{
        width: 120%;
    }

}