.btnShow{

}

.btnShow{
    background-color: #FF7C05;
    color: #fff;
    border: none;
    padding: 8px 23px;
    margin-top: 30px;
    transition: transform 0.3s ease-in-out;
}


.btnShow:hover{
    color: #FF7C05;
}

.btnContainer{
    display: flex;
    justify-content: center;
    align-items: center;
}

.bookData p , .bookData p  {
    font-size: 20px;
}